import {Component, OnInit} from '@angular/core';
import {PlanetService} from "../../../../core/services/planet.service";
import {AccountService} from "../../../../core/services/account.service";
import {environment} from "../../../../../environments/environment";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {NotifierService} from "../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {
  DialogDescriptionShipsComponent
} from "../../components/dialog/dialog-description-ships/dialog-description-ships.component";
import {MatDialog} from "@angular/material/dialog";
const scoreABI = require('src/app/core/abi/AstraScore.json');

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit{
  scoreLastWeek:any;
  scoreThisWeek:any;
  scoreNextWeek:any;
  type:string = 'off';
  address:any;
  planetSelected:any;
  dateNow:any;
  dateLimit:any;
  dataPrevWeek:any;
  dataThisWeek:any;
  dataNextWeek:any;
  timeUpdate:any;
  filterRanking:any;
  loadClaim:boolean=false;
  selected_week: string = '';
  constructor(
    private _dialog:MatDialog,
    private _planetService:PlanetService,
    private _accountService:AccountService,
    private _contractService:ContractService,
    private _notifierService:NotifierService,
    private _translateService:TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.dateNow = Math.floor(Date.now()/1000)
    this.dateLimit = this.dateNow + 604800
    this.dataThisWeek = Math.floor(Date.now()/1000)
    this.dataNextWeek = this.dateNow + 604800
    this.dataPrevWeek = this.dateNow - 604800
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getPlanet().subscribe({
            next:(planets) => {
              if (planets && planets.length > 0 && this.planetSelected === undefined) {
                let planetSelectedName = localStorage.getItem(this.address+'planetSelected')
                if(planetSelectedName){
                  const planet = planets.filter((data) => data.name === planetSelectedName)
                  if(planet){
                    this.planetSelected = planet[0]
                    this.updateData(this.planetSelected.id)
                  } else {
                    this.planetSelected = planets[0]
                    this.updateData(this.planetSelected.id)
                  }
                } else {
                  this.planetSelected = planets[0]
                  this.updateData(this.planetSelected.id)
                }
              } else {
                this.updateData(0)
              }
            }
          })
        }
      }
    });
  }

  updateData(id:any){
    this._planetService.getInfoPlanetScore(id,this.dataThisWeek).subscribe({
      next:(score)=>{
        this.scoreThisWeek = {
          rewardDef: this._planetService.formatBigNumber(score.rewardDef),
          rewardOff: this._planetService.formatBigNumber(score.rewardOff),
          rewardTotDef: this._planetService.formatBigNumber(score.rewardTotDef),
          rewardTotOff: this._planetService.formatBigNumber(score.rewardTotOff),
          roundInfo: score.roundInfo,
          roundToClaim: score.roundToClaim,
          scoreDef: score.scoreDef,
          scoreOff: score.scoreOff,
          scoreTotDef: score.scoreTotDef,
          scoreTotOff: score.scoreTotOff,
          startScore: score.startScore,
        };
        this.setType(this.type)
      }
    })
    this._planetService.getInfoPlanetScore(id,this.dataPrevWeek).subscribe({
      next:(scorePrevWeek)=>{
        this.scoreLastWeek = {
          rewardDef: this._planetService.formatBigNumber(scorePrevWeek.rewardDef),
          rewardOff: this._planetService.formatBigNumber(scorePrevWeek.rewardOff),
          rewardTotDef: this._planetService.formatBigNumber(scorePrevWeek.rewardTotDef),
          rewardTotOff: this._planetService.formatBigNumber(scorePrevWeek.rewardTotOff),
          roundInfo: scorePrevWeek.roundInfo,
          roundToClaim: scorePrevWeek.roundToClaim,
          scoreDef: scorePrevWeek.scoreDef,
          scoreOff: scorePrevWeek.scoreOff,
          scoreTotDef: scorePrevWeek.scoreTotDef,
          scoreTotOff: scorePrevWeek.scoreTotOff,
          startScore: scorePrevWeek.startScore,
        };
      }
    })
    this._planetService.getInfoPlanetScore(id,this.dataNextWeek).subscribe({
      next:(scoreNextWeek)=>{
        this.scoreNextWeek = {
          rewardDef: this._planetService.formatBigNumber(scoreNextWeek.rewardDef),
          rewardOff: this._planetService.formatBigNumber(scoreNextWeek.rewardOff),
          rewardTotDef: this._planetService.formatBigNumber(scoreNextWeek.rewardTotDef),
          rewardTotOff: this._planetService.formatBigNumber(scoreNextWeek.rewardTotOff),
          roundInfo: scoreNextWeek.roundInfo,
          roundToClaim: scoreNextWeek.roundToClaim,
          scoreDef: scoreNextWeek.scoreDef,
          scoreOff: scoreNextWeek.scoreOff,
          scoreTotDef: scoreNextWeek.scoreTotDef,
          scoreTotOff: scoreNextWeek.scoreTotOff,
          startScore: scoreNextWeek.startScore,
        };
      }
    })
  }

  setType(type:any){
    this.filterRanking = [];
    this._planetService.getRanking(this.scoreThisWeek.roundInfo.roundId).subscribe({
      next:(ranking)=>{
        this.timeUpdate = ranking.timeUpdate
        if(type === 'def'){
          this.filterRanking = ranking.rankingDefPosition
        } else if(type === 'off'){
          this.filterRanking = ranking.rankingOffPosition
        }
      }
    })
    this.type = type
  }


  setPage(type: any) {
    if(type === '+'){
      if(this.dateLimit > this.dataThisWeek + 604800){
        this.dataThisWeek = this.dataThisWeek + 604800
        this.dataNextWeek = this.dataThisWeek + 604800
        this.dataPrevWeek = this.dataThisWeek - 604800
        this.updateData(this.planetSelected ? this.planetSelected.id : 0)
      }
    } else {
      if(this.scoreThisWeek.startScore < this.dataThisWeek - 604800){
        this.dataThisWeek = this.dataThisWeek - 604800
        this.dataNextWeek = this.dataThisWeek + 604800
        this.dataPrevWeek = this.dataThisWeek - 604800
        this.updateData(this.planetSelected ? this.planetSelected.id : 0)
      }
    }
  }

  async claimAllReward(){
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadClaim = true;
    this._contractService.writeContract(environment.astraScore, scoreABI, 'claimReward', [this.planetSelected.id,this.scoreThisWeek.roundToClaim])
      .then(async (chargeProject) => {
        const charge = await chargeProject.wait();
        if(charge){
          this.loadClaim = false;
          this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reward_claimed'))
          this.updateData(this.planetSelected ? this.planetSelected.id : 0)
        }
      })
      .catch((err) => {
        const error = JSON.stringify(err)
        const data = JSON.parse(error)
        if(data.reason.includes('already claimed')) {
          this._notifierService.showNotificationError(this._translateService.instant('notification.already_claimed_reward'))
        } else {
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_claim_reward'))
        }
        this.loadClaim = false;
      });
  }

  openDescription(){
    const desc = this._translateService.instant('dashboard.statistics.desc1') + ' '+(this.scoreNextWeek.rewardTotOff + this.scoreNextWeek.rewardTotDef)+ ' FDC, '+this._translateService.instant('dashboard.statistics.desc2')
    this._dialog.open(DialogDescriptionShipsComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:desc,
    })
  }

  setWeek(str: string) {
    if (str === this.selected_week) {
      this.selected_week = '0';
    } else {
      this.selected_week = str;
    }
  }

}
