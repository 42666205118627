<div *ngIf="!this.loading" class="flex flex-col px-0 sm:px-2 gap-12 ">
  <div class="flex flex-row w-full items-center justify-start gap-8 md:pr-14">
    <div class="flex flex-row items-center justify-start gap-4">
      <label class="title text-white">{{'dashboard.fleet.title'|translate}}</label>
    </div>
    <div *ngIf="this.ships && this.ships.length >0" class="container_open_new_fleet flex flex-row items-center justify-between cursor-pointer px-4 gap-3" (click)="openNewFleet()">
      <label class="desc-h text-white cursor-pointer">{{"dashboard.fleet.new_fleet" | translate}}</label>
      <img src="assets/images/icon/new_fleet.svg" alt="" class="w-4 cursor-pointer">
    </div>
  </div>
  <div class="flex flex-row w-full max-w-md items-center justify-between gap-2">
    <button [class]="type === 'all' ? 'button_war !w-32 bg-light-purple text-white !m-0 flex items-center justify-center !font-bold':'button_war bg-light-gray !w-32 text-purple !m-0 flex items-center justify-center'" (click)="setFleet('all')">{{"dashboard.fleet.all" | translate}}</button>
    <button [class]="type === 'off' ? 'button_war !w-32 bg-light-purple text-white !m-0 flex items-center justify-center !font-bold':'button_war bg-light-gray !w-32 text-purple !m-0 flex items-center justify-center'" (click)="setFleet('off')">{{"dashboard.fleet.attack" | translate}}</button>
    <button [class]="type === 'def' ? 'button_war !w-32 bg-light-purple text-white !m-0 flex items-center justify-center !font-bold':'button_war bg-light-gray !w-32 text-purple !m-0 flex items-center justify-center'" (click)="setFleet('def')">{{"dashboard.fleet.defense" | translate}}</button>
  </div>
  <div class="flex flex-col-reverse xl:flex-row w-full items-start justify-between gap-4">
    <div class="flex flex-col w-full xl:w-3/4">
      <label *ngIf="fleets && fleets.length === 0" class="subtitle text-white text-center">{{"dashboard.fleet.no_fleet" | translate}}</label>
      <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-12 px-4 md:px-0">
        <div *ngFor="let fleet of filterFleet, let i = index"  >
          <div class="flex flex-col container_fleet bg-tab gap-4 items-start p-4" [ngStyle]="{background: 'linear-gradient(180deg, #121212 0%, rgba(19, 22, 39, 0.7) 100%),url('+fleet.url+')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center center' }">
            <div class="flex flex-row w-full items-center justify-between">
              <label class="title_fleet text-white">{{"dashboard.fleet."+fleet.name | translate}} {{fleet.id}}</label>
              <div class="flex flex-row items-center gap-2">
                <img src="assets/images/icon/view.svg" alt="" class="cursor-pointer w-5" (click)="viewShips(fleet)">
                <img src="assets/images/icon/edit.svg"
                     [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="fleet.type === 'def' && this.isPlanetInPhaseReveal ? ('notification.no_edit_battle_reveal'|translate): ''"
                     [class]="(fleet.isUsed || (fleet.type === 'def' && this.isPlanetInPhaseReveal)) ? 'grayscale cursor-auto w-5' :'cursor-pointer w-5'" alt="" (click)="editFleet(fleet)"/>
                <img *ngIf="this.fleetIndex !== i" src="assets/images/icon/delete.svg" [class]="fleet.isUsed ? 'grayscale cursor-auto w-5' :'cursor-pointer w-5'" alt="" (click)="removeFleet(fleet,fleet.index,fleet.type === 'off' ?'removeFleetOff':'removeFleetDef',i)"/>
                <mat-spinner *ngIf="loadRemove && this.fleetIndex === i" [diameter]="20"></mat-spinner>
              </div>
            </div>
            <div class="flex flex-col w-full gap-3 !mt-auto">
              <label *ngIf="fleet.type === 'off' && fleet.isUsed && dateNow > fleet.usedTo" class="desc-h text-red">{{"dashboard.fleet.fleet_used" | translate}}</label>
              <label *ngIf="fleet.type === 'off' && dateNow < fleet.usedTo" class="desc-h text-orange-400">{{"dashboard.fleet.fleet_time" | translate}}: <app-timer-hangar (updateFleet)="checkUpdateFleet($event,fleet)" [time]="fleet.usedTo" class="desc-h text-white"></app-timer-hangar></label>
              <label *ngIf="fleet.type === 'off'" class="subtitle_time text-white">{{"dashboard.fleet.attack" | translate}}: {{fleet.totalAtt}}</label>
              <label *ngIf="fleet.type === 'def'" class="subtitle_time text-white">{{"dashboard.fleet.defense" | translate}}: {{fleet.totalDef}}</label>
              <label class="subtitle_time text-white">{{"dashboard.fleet.resistance" | translate}}: {{fleet.resistance}} %</label>
              <label *ngIf="fleet.type === 'off'" class="subtitle_time text-white">{{"dashboard.fleet.velocity" | translate}}: {{fleet.speedMin}} {{"dashboard.war.mil_km/h" | translate}}</label>
              <label *ngIf="fleet.type === 'off'" class="subtitle_time text-white">{{"dashboard.fleet.fuel" | translate}}: {{fleet.kmHydro | currency: '':'':'1.2'}} {{"dashboard.war.mil_km/hydro" | translate}}</label>
              <label *ngIf="fleet.type === 'off'" class="subtitle_time text-white">{{"dashboard.fleet.hold" | translate}}: {{fleet.totalHold | currency: '':'':'1.2'}} {{"dashboard.ships.fdc" | translate}}</label>
            </div>
            <div class="flex flex-col w-full gap-2 mt-5">
              <div class="flex flex-row w-full items-center justify-between">
                <label class="subtitle_time text-white">{{"dashboard.fleet.size" | translate}}</label>
                <label class="subtitle_time text-white">{{fleet.totalSize}}/{{maxSizeFleet}}</label>
              </div>
              <mat-progress-bar color="primary" mode="determinate" value="{{fleet.totalSize * 2}}" class="!w-full"></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full h-full max-w-xl xl:w-1/4 gap-2">
      <label class="subtitle text-white">{{"dashboard.fleet.ships_available" | translate}}</label>
      <label *ngIf="myShipBalance && myShipBalance.length === 0" class="desc-h text-light-gray">{{"dashboard.fleet.no_ships_available" | translate}}</label>
      <div *ngIf="myShipBalance && myShipBalance.length > 0" class="flex flex-col bg-tab w-full gap-3 overflow-x-auto container_my_ships">
        <table class="w-full max-w-7xl py-4 px-4 text-sm text-left shadow-none">
          <thead class="text-xs text-light-gray uppercase bg-transparent">
          <tr>
            <th scope="col" class="p-2 desc-h text-light-purple cursor-default">
              {{"dialog.view_ships.type" | translate}}
            </th>
            <th scope="col" class="p-2 desc-h text-light-purple cursor-default">
              {{"dialog.view_ships.name" | translate}}
            </th>
            <th scope="col" class="p-2 desc-h text-light-purple cursor-default">
              {{"dialog.view_ships.size" | translate}}
            </th>
            <th scope="col" class="p-2 desc-h text-light-purple cursor-default">
              {{"dialog.view_ships.number" | translate}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let ship of myShipBalance" class="bg-transparent">
            <td class="p-2 desc-h text-white">
              <img [src]="ship.uri" alt="" class="w-16">
            </td>
            <td class="p-2 desc-h text-white">
              {{ship.name}}
            </td>
            <td class="p-2 desc-h text-white">
              {{ship.size}}
            </td>
            <td class="p-2 desc-h text-white">
              {{ship.amount}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
  <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
</div>
