// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl:  'https://engine-develop.astrafinance.io',
  fdc: "0x09D27bdF6805184b17D6092DF0Ae0E97c67e354C",
  hydrogen: "0x065de4ce5eAaA29b7b037Da3f46fA069fC342B10",
  astraManagerVRF: '0xdBB2a46d4Dd16b894FFbB263aAc18fcA967257DB',
  astraPlanet: "0xfe12a992A4c921Ed5877F4cF8B3bB012ac17375b",
  astraPlanetName: "0x1e4351145904FA3873F3266b32384CB1c4a2A9b1",
  astraFarmingManager: "0x40c3301bbCAcceCe24A89AC23ECB8bD2E4F9926d",
  astraFarmingImplement: "0x9dD47F19b10984F852f7aE026328656a2E571304",
  astraShip: "0xA567230F5831FCbF4B7B1Ff243CF824EC7fd5f79",
  astraBuilding: "0xE751C6551017c3F090f70734BA0Dcd8ba890956b",
  astraFleet: "0xeF3D8830DA9a0bCf2f26B96081d98A3C71EE270d",
  astraBattle: "0x489f6D7F1f8DA296aFfaF1E7512B7f91A9A5cD7d",
  astraBattleCalc: "0x48B9fE92D1CAEa01A0354173d35A8f68005Bc9A3",
  astraMisteryBox: "",
  astraSwapHydro: "0xF9E7C056a482B2f69BFE3413c8a72C17Fd268c5d",
  astraScore: "0x9B680219e95F5522d1d0F80E22871C7e0D6B683D",
  lpToken: "0xc5530970Ced7635277252eB948AD00340d7c07Ee"
};
