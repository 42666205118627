import {Component, DoCheck, OnInit} from '@angular/core';
import {PlanetService} from "../../../../core/services/planet.service";
import {AccountService} from "../../../../core/services/account.service";
import {environment} from "../../../../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {NotifierService} from "../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {DialogChangeNameComponent} from "../../components/dialog/dialog-change-name/dialog-change-name.component";
import {DialogChangeCivilizationComponent} from "../../components/dialog/dialog-change-civilization/dialog-change-civilization.component";
import {DialogViewShipsMintedComponent} from "../../components/dialog/dialog-view-ships-minted/dialog-view-ships-minted.component";
const buildingABI = require('src/app/core/abi/AstraBuilding.json');
const allShips = require('src/app/core/ships/ship.json');

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit,DoCheck{
  address='';
  civilizationId=-1;
  planetsLength: any;
  planetSelectedName: any;
  loading: boolean = false;
  noBuildShips: boolean = false;
  loadingClaim: boolean = false;
  loadingClaimAll: boolean = false;
  planets:any;
  planetsFilter:any[]=[];
  arrayStructure:any[]=[];
  arrayShips:any[]=[];
  arrayBattleDef:any[]=[];
  arrayBattleOff:any[]=[];
  allShips:any;
  planetSelected:any;
  totalTime:any;
  dateNow:any;
  farming:any;
  score:any;
  levelBuilding:any;
  bonusBuilding:any;
  selected_card: string = '';
  constructor(
    private _planetService:PlanetService,
    private _accountService:AccountService,
    private _contractService:ContractService,
    private _notifierService:NotifierService,
    private _translateService:TranslateService,
    private _dialog:MatDialog,
  ) {
  }

  ngOnInit(){
    this.dateNow = Math.floor(Date.now()/1000)
    this.loading = true;
    this.noBuildShips = false;
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this.planetSelectedName = localStorage.getItem(this.address+'planetSelected')
          if(this.planetSelectedName){
            if(this.address === account.address){
              this.planets = undefined;
              setTimeout(()=>{
                this.getPlanetData(this.planetSelectedName)
              },250)
            }
          } else {
            this.planetsLength = 0
            this.planetSelected = undefined
            this.civilizationId = -1
            this.planets = []
            this.arrayStructure = []
            this.arrayShips = []
            this.loading = false;
          }
        } else if(this.planetSelectedName !== localStorage.getItem(this.address+'planetSelected')){
          this.planets = undefined;
          this.getPlanetData(localStorage.getItem(this.address+'planetSelected'))
        }
      }
    });
  }

  ngDoCheck(): void {
    this.totalTime = Math.floor(Date.now()/1000)
  }

  getPlanetData(planetSelectedName:any){
    this._planetService.getPlanet().subscribe({
      next:async (planets) => {
        if (planets && planets.length > 0 && this.planets === undefined) {
          this.planets = planets
          this.planetSelectedName = localStorage.getItem(this.address + 'planetSelected')
          const planet = planets.filter((data) => data.name === planetSelectedName)
          if (planet && planet.length > 0) {
            this.planetSelected = planet[0]
            this.civilizationId = planet[0].civilization
            this.checkUpdate()
            this._planetService.getInfoPlanetStructure(this.planetSelected.id).subscribe({
              next:(info) => {
                if(info !== null){
                  this.levelBuilding = info.levelBuilding
                }
              }})
            this._planetService.getGlobalInfo().subscribe({
              next:(bonus) => {
                if(bonus !== null){
                  this.bonusBuilding = bonus.powerLevelBuilding;
                }
              }})
            this._planetService.getInfoPlanetFarming(this.planetSelected.id,this.address).subscribe({
              next:(farming)=>{
                if(farming !== null){
                  this.farming = {
                    pendingReward: this._planetService.formatBigNumber(farming.pendingReward),
                    percentageFeeWithdraw: farming.percentageFeeWithdraw,
                    isUnderAttack: farming ? farming.isUnderAttack : false,
                    scoreFarming: farming ? farming.scoreFarming : 0,
                  }
                }
              }
            })
            this._planetService.getInfoPlanetScore(this.planetSelected.id,Math.floor(Date.now()/1000)).subscribe({
              next:(score)=>{
                if(score !== null){
                  this.score = {
                    rewardDef: this._planetService.formatBigNumber(score.rewardDef),
                    rewardOff: this._planetService.formatBigNumber(score.rewardOff)
                  }
                }
              }
            })
            this._planetService.getSummaryInfo(this.planetSelected.id).subscribe({
              next: (info) => {
                if (info) {
                  this.loading = false;
                  this.getBattleDefOncoming(info.battleDefOncoming)
                  this.getBattleOffOncoming(info.battleOffOncoming)
                  this.getStructureInUpdate(info.availabilityBuilding)
                  this.getShipsInUpdate(info.buildShip)
                }
              }
            })
            this.allShips = this._planetService.createShips(allShips)
            this.planetsFilter = planets.filter((data) => data.name !== this.planetSelected.name)
            this.planetsLength = planets.length
          } else {
            this.planetSelected = planets[0]
            this.civilizationId = planets[0].civilization
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      }
    })
  }

  async changeName(planet:string,id:any){
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this._dialog.open(DialogChangeNameComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data: {planet:planet, id:id},
    }).afterClosed().subscribe(async (res) => {
      if (res && res.data) {
        this.updateDataPlanet(res.data)
      }
    });
  }

  async changeCivilization(planet:string,id:any){
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this._dialog.open(DialogChangeCivilizationComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{planet:planet, id:id},
    }).afterClosed().subscribe(async (res) => {
      if (res && res.data) {
        this.updateDataPlanet(res.data)
      }
    });
  }

  updateDataPlanet(name:any){
    this.loading = true;
    this._planetService.getUserInfo(this.address).subscribe({
      next:(info)=>{
        this._planetService.setAllPlanet(info.infoPlanets);
        localStorage.setItem(this.address+'planetSelected',name)
        this.planets = info.infoPlanets
        const planet = info.infoPlanets.filter((data:any) => data.name === name)
        this.planetSelected = planet[0]
        this.civilizationId = planet[0].civilization
        this.planetsFilter = info.infoPlanets.filter((data:any)=>data.name !== name)
        this.planetsLength = info.infoPlanets.length
        this.loading = false;
      },
      error:()=>{
      }
    })
  }

  checkUpdate(){
    setInterval(async () => {
      if((this.arrayStructure && this.arrayStructure.length >0) || (this.arrayShips && this.arrayShips.length >0)){
        let planetSelectedName = localStorage.getItem(this.address+'planetSelected')
        if(planetSelectedName === this.planetSelectedName){
          this.dateNow = Math.floor(Date.now()/1000)
          this.arrayStructure = this.arrayStructure.filter((structure)=> structure.time > this.dateNow)
        } else {
          if(planetSelectedName){
            localStorage.setItem(this.address+'planetSelected',planetSelectedName)
            this.getPlanetData(planetSelectedName)
          }
        }
      }
    },1000)
  }
  async getBattleDefOncoming(info:any) {
    let battleDef: any[] = [];
    if(info && info.length > 0){
      for (let i = 0 ; i < info.length; i++) {
        const data = {
          planetIdOff:info[i].planetIdOff,
          id:info[i].id,
          type:'def',
          confirmed:info[i].status.confirmed,
          revealed:info[i].status.revealed,
          revoked:info[i].status.revoked,
          timeRevealed:info[i].timeRevealed,
          planetNameDef:info[i].planetNameDef,
          planetIdDef:info[i].planetIdDef,
          planetNameOff:info[i].planetNameOff,
          startBattle:info[i].status.startBattle,
          battleDelay:info[i].battleDelay,
        }
        battleDef.push(data)
      }
      battleDef = battleDef.sort(function (
        a: { startBattle: number },
        b: { startBattle: number }
      ) {
        return a.startBattle - b.startBattle;
      });
      this.arrayBattleDef = battleDef
      this.loadingClaim = false;
    }else {
      battleDef = battleDef.sort(function (
        a: { startBattle: number },
        b: { startBattle: number }
      ) {
        return a.startBattle - b.startBattle;
      });
      this.arrayBattleDef = battleDef
      this.loadingClaim = false;
    }
  }
  async getBattleOffOncoming(info:any) {
    let battleOff: any[] = [];
    if(info && info.length > 0){
      for (let i = 0 ; i < info.length; i++) {
        const data = {
          planetIdOff:info[i].planetIdOff,
          id:info[i].id,
          type:'off',
          confirmed:info[i].status.confirmed,
          revealed:info[i].status.revealed,
          revoked:info[i].status.revoked,
          timeRevealed:info[i].timeRevealed,
          planetNameDef:info[i].planetNameDef,
          planetIdDef:info[i].planetIdDef,
          planetNameOff:info[i].planetNameOff,
          startBattle:info[i].status.startBattle,
          battleDelay:info[i].battleDelay,
        }
        battleOff.push(data)
      }
      if(battleOff.length === info.length){
        battleOff = battleOff.sort(function (
          a: { startBattle: number },
          b: { startBattle: number }
        ) {
          return a.startBattle - b.startBattle;
        });
        this.arrayBattleOff = battleOff
        this.loadingClaim = false;
      } else {
        battleOff = battleOff.sort(function (
          a: { startBattle: number },
          b: { startBattle: number }
        ) {
          return a.startBattle - b.startBattle;
        });
        this.arrayBattleOff = battleOff
        this.loadingClaim = false;
      }
    }else {
      battleOff = battleOff.sort(function (
        a: { startBattle: number },
        b: { startBattle: number }
      ) {
        return a.startBattle - b.startBattle;
      });
      this.arrayBattleOff = battleOff
      this.loadingClaim = false;
    }
  }
  async getStructureInUpdate(info:any) {
    const allStructures = []
    const timestamp = Math.floor(Date.now()/1000);
    if (timestamp < info.laboratory) {
      const structure = {name: 'laboratory', time:info.laboratory}
      allStructures.push(structure)
    }
    if (timestamp < info.hangar) {
      const structure = {name: 'hangar', time:info.hangar}
      allStructures.push(structure)
    }
    if (timestamp < info.armory) {
      const structure = {name: 'armory', time:info.armory}
      allStructures.push(structure)
    }
    if (timestamp < info.shield) {
      const structure = {name: 'shield', time:info.shield}
      allStructures.push(structure)
    }
    if(allStructures.length > 0){
      this.arrayStructure = allStructures
    } else {
      this.arrayStructure = []
    }
  }

  async getShipsInUpdate(info: any) {
    const shipsQueue: any[] = [];
    const arrayOfShips: any[] = [];
    if(info && info.length > 0){
      for (let i = 0 ; i < info.length; i++) {
        const data = {
          listAmount:info[i].listAmount,
          availability:info[i].availability,
          claimed:info[i].claimed,
          planetId:info[i].planetId,
          listType:info[i].listType,
          index:info[i].index,
        }
        shipsQueue.push(data)
      }
      if(shipsQueue && shipsQueue.length >0){
        for(let i = 0; i < shipsQueue.length; i ++){
          let sum = 0;
          for(let amount of shipsQueue[i].listAmount){
            sum = sum + Number(amount)
          }
          const allShips = {
            amounts: shipsQueue[i].listAmount,
            type: shipsQueue[i].listType,
          }
          arrayOfShips.push({index:+shipsQueue[i].index,amount: sum,endUpdateAllShips:+shipsQueue[i].availability,endUpdateShips:+shipsQueue[i].availability,ships:allShips})
        }
        this.arrayShips = arrayOfShips
        this.loadingClaim = false;
      } else {
        this.arrayShips = arrayOfShips
        this.loadingClaim = false;
      }
    }else {
      this.arrayShips = arrayOfShips
      this.loadingClaim = false;
    }
  }

  async claimMultipleShips(ships:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    const indexShips = []
    for(let data of ships){
      if(this.dateNow > data.endUpdateAllShips){
        indexShips.push(data.index)
      }
    }
    if(indexShips.length >0 && indexShips.length !== ships.length){
      this.loadingClaimAll = true;
      this._contractService.writeContract(environment.astraBuilding, buildingABI, 'claimShipBatch', [this.planetSelected.id, indexShips])
        .then(async (chargeProject) => {
          const charge = await chargeProject.wait();
          if(charge){
            const charge = await chargeProject.wait();
            if(charge){
              setTimeout(()=>{
                this._planetService.getSummaryInfo(this.planetSelected.id).subscribe({
                  next:(info) => {
                    this.loadingClaimAll = false;
                    this._notifierService.showNotificationSuccess(this._translateService.instant('notification.ships_claimed'))
                    this.getShipsInUpdate(info.buildShip)
                    this.checkUpdate()
                  }
                })
              } ,1000)
            }
          }
        })
        .catch((err) => {
          this.loadingClaimAll = false;
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          if (data.reason.includes('claim not available')) {
            this._notifierService.showNotificationError(this._translateService.instant('notification.claim_not_available'))
          }
        });
    } else if(indexShips.length === ships.length){
      this.loadingClaimAll = true;
      this._contractService.writeContract(environment.astraBuilding, buildingABI, 'claimAllShip', [this.planetSelected.id])
        .then(async (chargeProject) => {
          const charge = await chargeProject.wait();
          if(charge){
            setTimeout(()=>{
              this._planetService.getSummaryInfo(this.planetSelected.id).subscribe({
                next:(info) => {
                  this.loadingClaimAll = false;
                  this._notifierService.showNotificationSuccess(this._translateService.instant('notification.ships_claimed'))
                  this.getShipsInUpdate(info.buildShip)
                  this.checkUpdate()
                }
              })
            } ,1000)
          }
        })
        .catch((err) => {
          this.loadingClaimAll = false;
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          if (data.reason.includes('claim not available')) {
            this._notifierService.showNotificationError(this._translateService.instant('notification.claim_not_available'))
          }
        });
    }
  }

  getFinishBuild(){
    const indexShips = []
    for(let data of this.arrayShips){
      if(this.dateNow > data.endUpdateAllShips){
        indexShips.push(data.index)
      }
    }

    return indexShips.length > 0
  }
  viewShips(ships:any){
    this._dialog.open(DialogViewShipsMintedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.allShips},
    })
  }

  getTimeBuild(data:any){
    const hours = Math.floor(data / 3600);
    const minutes = Math.floor((data % 3600) / 60);
    const remainingSeconds = data % 60;
    let formatData = hours + "h " + minutes + "m " + remainingSeconds + "s"
    if(hours === 0){
      formatData = minutes + "m " + remainingSeconds + "s"
    }
    return formatData;
  }

  selectCard(str: string) {
    if (str === this.selected_card) {
      this.selected_card = '0';
    } else {
      this.selected_card = str;
    }
  }
}
