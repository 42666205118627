<div *ngIf="!this.loading" class="flex flex-col xl:flex-row gap-8 2xl:gap-12 px-0 sm:px-2 md:pr-6 2xl:pr-20 md:pl-0">
  <div class="flex flex-col items-start justify-between w-full gap-6 ">
    <div class="flex flex-row items-start justify-start gap-4 w-full max-w-lg">
      <div class="flex flex-col items-center">
        <img *ngIf="civilizationId === 1" class="h-16" src="assets/images/factions/signarok.png" alt="" >
        <img *ngIf="civilizationId === 2" class="h-16" src="assets/images/factions/numaya.png" alt="" >
        <img *ngIf="civilizationId === 3" class="h-16" src="assets/images/factions/afarit.png" alt="" >
      </div>
      <div class="flex flex-row items-center w-full">
        <div class="flex flex-col items-start w-full">
          <label *ngIf="planetSelected === undefined" class="title text-white">{{"dashboard.summary.no_planet" | translate}}</label>
          <label *ngIf="planetSelected && planetSelected.name !== ''" class="title text-white">{{planetSelected.name}}</label>
          <label *ngIf="planetSelected && planetSelected.name === ''" class="title text-white">{{"dashboard.summary.reveal" | translate}}</label>
          <label *ngIf="civilizationId === 1" class="desc-h text-light-purple">Signarok</label>
          <label *ngIf="civilizationId === 2" class="desc-h text-light-purple">Numaya</label>
          <label *ngIf="civilizationId === 3" class="desc-h text-light-purple">Afarit</label>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon button with a horizontal three dot icon">
          <mat-icon class="text-light-purple w-10">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="!bg-black">
          <button mat-menu-item  class="!text-white" (click)="changeName(planetSelected,planetSelected.id)">{{'dashboard.summary.edit_name'|translate}}</button>
          <button mat-menu-item  class="!text-white" (click)="changeCivilization(planetSelected,planetSelected.id)">{{'dashboard.summary.change_civ'|translate}}</button>
        </mat-menu>
      </div>
    </div>
    <div class="flex flex-col-reverse md:flex-row w-full md:w-5/6 gap-5 2xl:gap-10">
      <div class="flex flex-col gap-4 items-center">
        <div class="mb-6 planet_image flex flex-col items-start justify-between p-4"
             [ngStyle]="{background: planetSelected ? 'url(assets/images/planet_0.png)' : 'url(assets/images/no_planet.png)', 'background-repeat': 'no-repeat', 'background-size': 'cover' }">
        </div>
        <button *ngIf="!planetSelected" class="button bg-purple text-white container_mint !mx-auto" [routerLink]="'/dashboard/planet'">{{'dashboard.summary.mint_planet'|translate}}</button>
        <div *ngIf="planetSelected &&  planetSelected.protectedTime > 0 && totalTime < planetSelected.protectedTime" class="flex flex-col items-start justify-start">
          <label class="desc-h p-2 text-red">{{"dashboard.summary.protected" | translate}}</label>
          <app-timer  [time]="planetSelected.protectedTime" class="px-1 desc-s text-red"></app-timer>
        </div>
        <div class="container_info flex flex-col gap-4 items-start justify-start p-4">
          <label class="title_time text-white">{{'dashboard.summary.bonus'|translate}}</label>
          <div class="flex flex-row items-start w-full gap-8" >
            <div class="flex flex-col items-start w-full gap-2" >
              <label class="subtitle text-light-purple">{{"dashboard.summary.LEVEL" | translate}} <label class="text-white">{{planetSelected ? planetSelected.level : '0'}}</label></label>
            </div>
            <div class="flex flex-col items-start w-full gap-2" >
              <label class="subtitle text-light-purple">{{"dashboard.summary.ID" | translate}} <label class="text-white">{{planetSelected ? planetSelected.id : '0'}}</label></label>
            </div>
          </div>
          <div class="flex flex-row items-start w-full gap-8" >
            <div class="flex flex-col items-start w-full gap-2" >
              <label class="subtitle text-light-purple">{{"dashboard.summary.COORDINATE" | translate}} <label class="text-white">{{planetSelected ? planetSelected.coordinate[0] + ' ; ' +planetSelected.coordinate[1] : '0,0'}}</label></label>
            </div>
          </div>
        </div>
        <div class="container_info flex flex-col gap-4 items-start justify-start p-4">
          <label class="title_time text-white">{{'dashboard.summary.bonus'|translate}}</label>
          <div class="flex flex-row items-start w-full gap-2" >
            <div class="flex flex-col items-start w-full gap-2" >
              <label class="subtitle text-light-purple">{{"dashboard.summary.fleet" | translate}}:</label>
              <label class="ml-4 subtitle sm:desc-h text-white">{{this.bonusBuilding && this.levelBuilding && this.levelBuilding.hangar >0 ? this.bonusBuilding.hangar.power[this.levelBuilding.hangar -1] +1 : 1}} {{"dashboard.summary.attack" | translate}}</label>
              <label class="ml-4 subtitle sm:desc-h text-white">{{this.bonusBuilding && this.levelBuilding && this.levelBuilding.hangar >0 ? this.bonusBuilding.hangar.power[this.levelBuilding.hangar -1] +1 : 1}} {{"dashboard.summary.defense" | translate}}</label>
            </div>
            <div class="flex flex-col items-start w-full gap-2" >
              <label class="subtitle text-light-purple">{{"dashboard.summary.ships" | translate}}:</label>
              <label class="ml-4 subtitle sm:desc-h text-white"> {{"dashboard.summary.level" | translate}} {{this.bonusBuilding && this.levelBuilding && this.levelBuilding.armory > 0  ? this.bonusBuilding.armory.power[this.levelBuilding.armory -1] : 0}}</label>
            </div>
          </div>
          <div class="flex flex-row items-start w-full gap-2" >
            <div class="flex flex-col items-start w-full gap-2" >
              <label class="subtitle text-light-purple">{{"dashboard.summary.time_build" | translate}}:</label>
              <label class="ml-4 subtitle sm:desc-h text-white">- {{this.bonusBuilding && this.levelBuilding && this.levelBuilding.laboratory > 0 ? (this.bonusBuilding.laboratory.power[this.levelBuilding.laboratory -1]) : 0}}%</label>
            </div>
            <div class="flex flex-col items-start w-full gap-2" >
              <label class="subtitle text-light-purple">{{"dashboard.summary.defense_bonus" | translate}}:</label>
              <label class="ml-4 subtitle sm:desc-h text-white">+ {{this.bonusBuilding && this.levelBuilding && this.levelBuilding.shield > 0 ? (this.bonusBuilding.shield.power[this.levelBuilding.shield -1]) : 0}}%</label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full gap-4">
        <mat-expansion-panel class="!bg-tab !shadow-none !rounded-none py-2 !outline-0 w-full sm:w-11/12 lg:w-full xl:w-11/12 max-w-xl" hideToggle="true" [expanded]="this.selected_card === '1'">
          <mat-expansion-panel-header (click)="selectCard('1')" class="!bg-transparent !outline-0">
            <mat-panel-title class="flex justify-between gap-5">
              <label class="subtitle text-light-purple ml-4">{{'dashboard.summary.attack_time'|translate}}: <label class="subtitle text-white !font-light">{{arrayBattleOff.length}}</label></label>
              <span class="icon"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flex flex-col ml-4 gap-2">
          <table *ngIf="arrayBattleOff && arrayBattleOff.length >0" class="w-full py-4 px-4 text-sm text-left shadow-none">
            <thead class="text-xs text-light-gray uppercase bg-transparent">
            <tr>
              <th scope="col" class="desc-h text-light-purple cursor-default">{{"dashboard.summary.NAME_PLANET" | translate}}</th>
              <th scope="col" class="desc-h text-light-purple cursor-default">{{"dashboard.summary.TIME" | translate}}</th>
              <th scope="col" class="desc-h text-light-purple cursor-default text-end">{{"dashboard.summary.VIEW" | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let battleOff of arrayBattleOff" class="text_table text-white justify-between ">
              <td *ngIf="battleOff"  class="py-1">
                <label class="desc-h text-white">{{battleOff.planetNameDef}}</label>
              </td>
              <td *ngIf="battleOff"  class="py-1">
                <label *ngIf="totalTime > battleOff.startBattle && !battleOff.revealed && !battleOff.confirmed" [class]="totalTime > battleOff.startBattle + battleOff.battleDelay.delayAttack ? 'desc-s text-red' : 'desc-s text-light-purple'">{{"dashboard.war.waiting" | translate}}<br>
                  <app-timer-countdown [time]="battleOff.startBattle" class="desc-s text-white mx-auto"></app-timer-countdown>
                </label>
                <label *ngIf="totalTime > battleOff.startBattle && !battleOff.revealed && battleOff.confirmed && !battleOff.revoked" class="desc-s text-red">{{"dashboard.war.revelation" | translate}}</label>
                <label *ngIf="totalTime < battleOff.startBattle" class="desc-s text-light-purple">{{"dashboard.war.arriving" | translate}}<br>
                  <app-timer [time]="battleOff.startBattle" class="desc-s text-white mx-auto"></app-timer></label>
              </td>
              <td  class="py-1">
                <img src="assets/images/icon/view.svg" alt="" class="w-4 cursor-pointer ml-auto mr-5" [routerLink]="'/dashboard/war/off/now/'+battleOff.id">
              </td>
            </tr>
            </tbody>
          </table>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="!bg-tab !shadow-none !rounded-none py-2 !outline-0 w-full sm:w-11/12 lg:w-full xl:w-11/12 max-w-xl" hideToggle="true" [expanded]="this.selected_card === '2'">
          <mat-expansion-panel-header (click)="selectCard('2')" class="!bg-transparent !outline-0">
            <mat-panel-title class="flex justify-between gap-5">
              <label class="subtitle text-light-purple ml-4">{{'dashboard.summary.defence_time'|translate}}: <label class="subtitle text-white !font-light">{{arrayBattleDef.length}}</label></label>
              <span class="icon"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flex flex-col ml-4 gap-2">
          <table *ngIf="arrayBattleDef && arrayBattleDef.length >0" class="w-full py-4 px-4 text-sm text-left shadow-none">
            <thead class="text-xs text-light-gray uppercase bg-transparent">
            <tr>
              <th scope="col" class="desc-h text-light-purple cursor-default">{{"dashboard.summary.NAME_PLANET" | translate}}</th>
              <th scope="col" class="desc-h text-light-purple cursor-default">{{"dashboard.summary.TIME" | translate}}</th>
              <th scope="col" class="desc-h text-light-purple cursor-default text-end">{{"dashboard.summary.VIEW" | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let battleDef of arrayBattleDef" class="text_table text-white justify-between ">
              <td *ngIf="battleDef"  class="py-1">
                <label class="desc-h text-white">{{battleDef.planetNameOff}}</label>
              </td>
              <td *ngIf="battleDef"  class="py-1">
                <label *ngIf="totalTime > battleDef.startBattle && !battleDef.revealed && !battleDef.confirmed" [class]="totalTime > battleDef.startBattle + battleDef.battleDelay.delayAttack ? 'desc-s text-red' : 'desc-s text-light-purple'">{{"dashboard.war.waiting" | translate}}<br>
                  <app-timer-countdown [time]="battleDef.startBattle" class="desc-s text-white mx-auto"></app-timer-countdown>
                </label>
                <label *ngIf="totalTime > battleDef.startBattle && !battleDef.revealed && battleDef.confirmed && !battleDef.revoked" class="desc-s text-red">{{"dashboard.war.revelation" | translate}}</label>
                <label *ngIf="totalTime < battleDef.startBattle" class="desc-s text-light-purple">{{"dashboard.war.arriving" | translate}}<br>
                  <app-timer [time]="battleDef.startBattle" class="desc-s text-white mx-auto"></app-timer></label>
              </td>
              <td  class="py-1">
                <img src="assets/images/icon/view.svg" alt="" class="w-4 cursor-pointer ml-auto mr-5" [routerLink]="'/dashboard/war/def/now/'+battleDef.id">
              </td>
            </tr>
            </tbody>
          </table>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="!bg-tab !shadow-none !rounded-none py-2 !outline-0 w-full sm:w-11/12 lg:w-full xl:w-11/12 max-w-xl" hideToggle="true" [expanded]="this.selected_card === '3'">
          <mat-expansion-panel-header (click)="selectCard('3')" class="!bg-transparent !outline-0">
            <mat-panel-title class="flex justify-between gap-5">
              <label class="subtitle text-light-purple ml-4">{{'dashboard.summary.farming'|translate}}</label>
              <span class="icon"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flex flex-col ml-4 gap-2">
            <label class="subtitle text-light-purple">{{'dashboard.farming.reward'|translate}}: <label class="desc-h text-white">{{this.farming ? (this.farming.pendingReward | currency: '':'':'1.2') : 0}} FDC</label></label>
            <label class="subtitle text-light-purple">{{'dashboard.farming.percentage_fees'|translate}}: <label class="desc-h text-white">{{this.farming ? this.farming.percentageFeeWithdraw : 0}} %</label></label>
            <label class="subtitle text-light-purple">{{'dashboard.farming.score_def'|translate}}:  <label class="desc-h text-white">{{this.farming ? (this.farming.scoreFarming | currency: '':'':'1.0') : 0}}</label></label>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="!bg-tab !shadow-none !rounded-none py-2 !outline-0 w-full sm:w-11/12 lg:w-full xl:w-11/12 max-w-xl" hideToggle="true" [expanded]="this.selected_card === '4'">
          <mat-expansion-panel-header (click)="selectCard('4')" class="!bg-transparent !outline-0">
            <mat-panel-title class="flex justify-between gap-5">
              <label class="subtitle text-light-purple ml-4">{{'dashboard.summary.score'|translate}}</label>
              <span class="icon"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flex flex-col ml-4 gap-2">
            <label class="subtitle text-light-purple">{{'dashboard.statistics.reward_def'|translate}}: <label class="desc-h text-white">{{this.score ? (this.score.rewardDef | currency: '':'':'1.2'): 0}} FDC</label></label>
            <label class="subtitle text-light-purple">{{'dashboard.statistics.reward_off'|translate}}: <label class="desc-h text-white">{{this.score ? (this.score.rewardOff | currency: '':'':'1.2'): 0}} FDC</label></label>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="!bg-tab !shadow-none !rounded-none py-2 !outline-0 w-full sm:w-11/12 lg:w-full xl:w-11/12 max-w-xl" hideToggle="true" [expanded]="this.selected_card === '5'">
          <mat-expansion-panel-header (click)="selectCard('5')" class="!bg-transparent !outline-0">
            <mat-panel-title class="flex justify-between gap-5">
              <label class="subtitle text-light-purple ml-4">{{'dashboard.summary.structure_time'|translate}}</label>
              <span class="icon"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flex flex-col ml-4 gap-2">
          <table *ngIf="arrayStructure && arrayStructure.length >0" class="w-full py-4 px-4 text-sm text-left shadow-none">
            <thead class="text-xs text-light-gray uppercase bg-transparent">
            <tr>
              <th scope="col" class="desc-h text-light-purple cursor-default">{{"dashboard.summary.NAME" | translate}}</th>
              <th scope="col" class="desc-h text-light-purple cursor-default">{{"dashboard.summary.TIME" | translate}}</th>
              <th scope="col" class="desc-h text-light-purple cursor-default text-end">{{"dashboard.summary.VIEW" | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of arrayStructure" class="text_table text-white justify-between ">
              <td *ngIf="data"  class="py-1">
                <label class="desc-h text-white">{{"dashboard.summary."+data.name | translate}}</label>
              </td>
              <td *ngIf="data"  class="py-1">
                <app-timer *ngIf="totalTime < data.time" [time]="data.time" class="desc-s text-white"></app-timer>
                <label *ngIf="totalTime >= data.time" class="desc-h text-white">Completata</label>
              </td>
              <td *ngIf="data"  class="py-1">
                <img src="assets/images/icon/view.svg" alt="" class="w-4 cursor-pointer ml-auto mr-5" [routerLink]="'/dashboard/structure/'+data.name">
              </td>
            </tr>
            </tbody>
          </table>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="!bg-tab !shadow-none !rounded-none py-2 !outline-0 w-full sm:w-11/12 lg:w-full xl:w-11/12 max-w-xl" hideToggle="true" [expanded]="this.selected_card === '6'">
          <mat-expansion-panel-header (click)="selectCard('6')" class="!bg-transparent !outline-0">
            <mat-panel-title class="flex justify-between gap-5">
              <label class="subtitle text-light-purple ml-4">{{'dashboard.summary.build_time'|translate}}</label>
              <button *ngIf="arrayShips && arrayShips.length >0 && !loadingClaimAll" [class]="getFinishBuild() ? 'button_switch !h-6 !w-20 bg-light-purple text-white !m-0 flex items-center justify-center' : 'button_switch !h-6 !w-20 text-white bg-dark-black opacity-60 !m-0 flex items-center justify-center'" [disabled]="!getFinishBuild()" (click)="claimMultipleShips(arrayShips)">{{'dashboard.summary.claim_all'|translate}}</button>
              <button *ngIf="loadingClaimAll" [class]="'button_switch !h-6 !w-20 bg-light-purple text-white !m-0 flex items-center justify-center'">
                <mat-spinner [diameter]="20"></mat-spinner>
              </button>
              <span class="icon"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flex flex-col ml-4 gap-2">
          <table *ngIf="!loadingClaim && arrayShips && arrayShips.length >0" class="w-full py-4 px-4 text-sm text-left shadow-none">
            <thead class="text-xs text-light-gray uppercase bg-transparent">
            <tr>
              <th scope="col" class="desc-h text-light-purple cursor-default">{{"dashboard.summary.QUANTITY" | translate}}</th>
              <th scope="col" class="desc-h text-light-purple cursor-default">{{"dashboard.summary.TIME" | translate}}</th>
              <th scope="col" class="desc-h text-light-purple cursor-default text-end">{{"dashboard.summary.VIEW" | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of arrayShips;let i = index" class="text_table text-white justify-between">
              <td *ngIf="data" class="py-1">
                <label class="subtitle_time text-white">{{data.amount}}</label>
              </td>
              <td *ngIf="data"  class="py-1">
                <app-timer *ngIf="i === 0 && this.dateNow < data.endUpdateAllShips" [time]="data.endUpdateAllShips" class="desc-s text-white w-16"></app-timer>
                <app-timer *ngIf="i !== 0 && this.dateNow > arrayShips[i-1].endUpdateAllShips" [time]="data.endUpdateAllShips" class="desc-s text-white w-16"></app-timer>
                <label *ngIf="i !== 0 && this.dateNow < arrayShips[i-1].endUpdateAllShips" class="desc-h text-orange-400">{{"dashboard.summary.waiting" | translate}} - {{getTimeBuild(arrayShips[i].endUpdateAllShips - arrayShips[i-1].endUpdateAllShips)}}</label>
                <label *ngIf="this.dateNow > data.endUpdateAllShips " class="desc-h text-light-purple">{{"dashboard.summary.finish" | translate}}</label>
              </td>
              <td *ngIf="data"  class="py-1 ">
                <img src="assets/images/icon/view.svg" alt="" class="w-4 cursor-pointer ml-auto mr-5" (click)="viewShips(data.ships)">
              </td>
            </tr>
            </tbody>
          </table>
          </div>
          <div *ngIf="loadingClaim" class="flex flex-col w-full h-24 items-center justify-center">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
  <div *ngIf="planetsFilter && planetsFilter.length >0 && planetSelected" class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-1 items-start gap-2 justify-around w-full xl:w-56 container_scroll_planet px-2">
    <div *ngFor="let planet of planetsFilter" >
      <div *ngIf="planet.name !== planetSelected.name" class="mt-0 mb-6 container_planet flex flex-col items-start justify-between p-4"
           [ngStyle]="{background: 'url(assets/images/planet_0.png)', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center center' }">
        <label class="subtitle text-white">{{planet.coordinate[0] + ' ; ' +planet.coordinate[1] }}</label>
        <div class="flex flex-col gap-1">
          <label class="subtitle text-white">{{planet.name === '' ? ("dashboard.summary.reveal" | translate):planet.name}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
  <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
</div>
