<div *ngIf="!this.loading" class="flex flex-col px-0 sm:px-2 gap-10">
  <div *ngIf="newBattle" class="flex flex-col gap-10">
    <div class="flex flex-col gap-2">
      <div class="flex flex-row items-center justify-start gap-10">
        <label class="title text-white">{{'dashboard.war.search_planet'|translate}}</label>
        <img src="assets/images/icon/back.svg" class="w-6 cursor-pointer" (click)="cancelAttack()"  alt="">
      </div>
      <label class="subtitle text-light-gray">{{'dashboard.war.subtitle_search'|translate}}</label>
      <div class="flex flex-col mt-4">
        <form [formGroup]="planetForm" class="flex flex-row gap-4 items-center">
          <input class="input-coordinate desc-h text-white !h-8 outline-0" placeholder="{{'dashboard.war.ID'|translate}}" formControlName="id" />
          <button [class]="this.planetForm.get('id')?.value === '' ? 'button bg-dark-black text-white !h-7 !w-36 !m-0 opacity-60' : 'button bg-purple text-white !h-7 !w-36 !m-0'" [disabled]="this.planetForm.get('id')?.value === ''" (click)="searchPlanet()">{{'dashboard.war.search'|translate}}</button>
          <label *ngIf="this.infoPlanetToAttack && this.infoPlanetToAttack.isProtected" class="subtitle text-red">{{"dashboard.war.protected" | translate}} {{this.infoPlanetToAttack.protectedTime *1000  | date: "dd-MM-YYYY HH:mm":"it"}}</label>
        </form>
      </div>
    </div>
    <div *ngIf="infoPlanetToAttack" class="flex flex-col responsive_table">
      <table class="table-responsive w-full max-w-7xl text-sm text-left bg-transparent shadow-none">
        <thead class="bg-transparent">
        <tr>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">{{"dashboard.war.planet" | translate}}</th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">{{"dashboard.war.power" | translate}}</th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">{{"dashboard.war.shield" | translate}}</th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            <div class="flex flex-row items-center gap-2">
              {{"dashboard.war.delta" | translate}} <img src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer" (click)="openDescription('dashboard.global.delta_description')">
            </div>
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            <div class="flex flex-row items-center gap-2">
              {{"dashboard.war.max_reward" | translate}} <img src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer" (click)="openDescription('dashboard.global.reward_description')">
            </div>
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            <div class="flex flex-row items-center gap-2">
              <label>{{"dashboard.war.fee_farming" | translate}}</label>
              <img src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer" (click)="openDescription('dashboard.global.fee_farming_description')">
            </div>
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">{{"dashboard.war.distance" | translate}}</th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">{{"dashboard.war.civilization" | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr [class]="'bg-transparent tab_protected'">
          <td [attr.aria-label]="'dashboard.war.planet' | translate" class="p-2 text-light-purple subtitle">
            <label class="desc-h text-white">{{infoPlanetToAttack.name}}</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.power' | translate" class="p-2 text-light-purple subtitle">
            <label class="desc-h text-white">{{infoPlanetToAttack.power}}</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.shield' | translate" class="p-2 text-light-purple subtitle">
            <label class="desc-h text-white">{{infoPlanetToAttack.levelShield}}</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.delta' | translate" class="p-2 text-light-purple subtitle">
            <img *ngIf="innerWidth < 500" src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer mr-auto" (click)="openDescription('dashboard.global.delta_description')">
            <label class="desc-h text-white">{{infoPlanetToAttack.deltaLevelPlanet}}%</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.max_reward' | translate" class="p-2 text-light-purple subtitle">
            <img *ngIf="innerWidth < 500" src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer mr-auto" (click)="openDescription('dashboard.global.reward_description')">
            <label class="desc-h text-white">{{infoPlanetToAttack.maxRewardToStole | currency: '':'':'1.2'}} FDC</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.fee_farming' | translate" class="p-2 text-light-purple subtitle">
            <img *ngIf="innerWidth < 500" src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer mr-auto" (click)="openDescription('dashboard.global.fee_farming_description')">
            <label class="desc-h text-white">{{infoPlanetToAttack.percentageFeeWithdraw}}%</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.distance' | translate" class="p-2 text-light-purple subtitle">
            <label class="desc-h text-white">{{infoPlanetToAttack.distance}} {{"dashboard.war.mil_km" | translate}}</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.civilization' | translate" class="p-2 text-light-purple subtitle">
            <div class="flex flex-row items-center justify-start gap-4">
              <img *ngIf="infoPlanetToAttack.isProtected" class="w-5" src="assets/images/icon/lock.svg" alt="" >
              <img *ngIf="infoPlanetToAttack.planetsCivilizationId === 1" class="h-7" src="assets/images/factions/signarok.png" alt="" >
              <img *ngIf="infoPlanetToAttack.planetsCivilizationId === 2" class="h-7" src="assets/images/factions/numaya.png" alt="" >
              <img *ngIf="infoPlanetToAttack.planetsCivilizationId === 3" class="h-7" src="assets/images/factions/afarit.png" alt="" >
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="infoPlanetToAttack" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full  max-w-7xl">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
          <label class="subtitle text-light-purple">{{"dashboard.war.select_fleet" | translate}}</label>
          <button *ngIf="fleets !== undefined" [matMenuTriggerFor]="menu" class="styling_select">
            {{"dashboard.war.fleet" | translate}}
          </button>
          <mat-menu #menu="matMenu" class="flex flex-col !bg-tab !rounded-none !w-36 !mt-2">
            <div mat-menu-item *ngFor="let fleet of fleets;let i = index" (click)="!this.fleetAttack.includes(fleet) && addShipToAttack(fleet)">
              <div class="flex !flex-row items-center gap-2 !py-2" >
                <span class="subtitle text-light-gray"> {{"dashboard.fleet.fleet" | translate}} {{fleet.index +1}}</span>
                <div *ngIf="!this.fleetAttack.includes(fleet)" class="rounded-full h-3 w-3 bg-transparent border-light-purple border-2 cursor-pointer" ></div>
                <div *ngIf="this.fleetAttack.includes(fleet)" class="rounded-full h-3 w-3  bg-light-purple"></div>
              </div>
            </div>
          </mat-menu>
        </div>
        <div [class]="'container_data_fleet flex flex-col gap-2'">
          <label class="subtitle text-light-gray">{{'dashboard.war.fleet_selected'|translate}}</label>
          <label *ngIf="fleets === undefined" class="subtitle text-red">{{'dashboard.war.no_fleet'|translate}}</label>
          <div *ngIf="viewInfoBattle" class="flex flex-row items-center gap-4">
            <label class="desc-h text-light-purple !w-32">{{'dashboard.war.total_attack_value'|translate}}: <label class="text-light-gray">{{viewInfoBattle.totAtt}}</label></label>
            <label class="desc-h text-light-purple !w-40">{{'dashboard.war.fleet_resistance'|translate}}: <label class="text-light-gray">{{viewInfoBattle.resistanceOff}} %</label></label>
          </div>
          <label *ngIf="viewInfoBattle" class="desc-h text-light-purple">{{"dashboard.war.speed_fleet" | translate}}: <label class="text-light-gray">{{viewInfoBattle.speedMin}} {{"dashboard.war.mil_km/h" | translate}}</label></label>
          <label *ngIf="viewInfoBattle" class="desc-h text-light-purple">{{"dashboard.war.consume_fleet" | translate}}: <label class="text-light-gray">{{viewInfoBattle.kmHydro}} {{"dashboard.war.mil_km/hydro" | translate}}</label></label>
          <div *ngIf="fleetAttack.length >0 && this.viewInfoBattle" class="flex flex-col gap-2 overflow-x-auto !h-20">
            <div *ngFor="let fleet of fleetAttack;let i = index" class="button_fleet_battle bg-purple text-white flex !flex-row items-center gap-2 cursor-pointer !p-2">
              <span class="desc-h text-light-gray"> {{"dashboard.fleet."+fleet.name | translate}} {{fleet.id}}</span>
              <img src="assets/images/icon/view.svg" class="w-4 cursor-pointer" (click)="viewInfoFleet(fleet)" alt="">
              <img src="assets/images/icon/close_light.svg" class="w-4 cursor-pointer" (click)="removeShipsToAttack(i)" alt="">
            </div>
          </div>
          <label *ngIf="fleetAttack.length >0 && this.viewInfoBattle" class="subtitle text-light-gray !mt-auto">{{'dashboard.war.total_ships'|translate}}: {{this.viewInfoBattle.totShips}}</label>
        </div>
      </div>
      <div [class]="'container_data_fleet flex flex-col gap-4'" >
        <label class="subtitle text-light-gray">{{"dashboard.war.preview_attack" | translate}}</label>
        <div *ngIf="fleetAttack.length >0 && this.viewInfoBattle"  class="flex flex-col gap-3">
          <label class="desc-h text-light-purple">{{"dashboard.war.probabilities_win" | translate}}: <label class="text-light-gray">{{viewInfoBattle.probabilitiesToWin}} %</label></label>
          <label class="desc-h text-light-purple">{{"dashboard.war.transportable_fdc" | translate}}: <label class="text-light-gray">{{viewInfoBattle.totalHold | currency: '':'':'1.2'}} FDC</label></label>
          <label class="desc-h text-light-purple">{{"dashboard.war.travel_time" | translate}}: <label class="text-light-gray">{{viewInfoBattle.estimatedTime}}</label></label>
          <label class="desc-h text-light-purple">{{"dashboard.war.total_fuel_cons" | translate}}: <label class="text-light-gray">{{viewInfoBattle.totalFuelCons | currency: '':'':'1.2'}} HYD</label></label>
        </div>
      </div>
      <div [class]="'container_data_fleet_defense flex flex-col gap-2'">
        <div class="flex flex-row items-center gap-8">
          <label class="subtitle text-light-gray">{{'dashboard.war.fleet_enemy'|translate}}</label>
        </div>
        <div class="flex flex-row items-center gap-8">
          <label class="desc-h text-light-purple">{{'dashboard.war.fleets_def'|translate}}: <label class="text-light-gray">{{viewInfoBattleDef ? viewInfoBattleDef.totDef : 0}}</label></label>
          <label class="desc-h text-light-purple">{{'dashboard.war.fleets_resistance'|translate}}: <label class="text-light-gray">{{viewInfoBattleDef ? viewInfoBattleDef.resistanceDef : 0}} %</label></label>
        </div>
        <div class="flex flex-col gap-4 overflow-x-auto !h-52">
          <div *ngFor="let fleet of infoPlanetToAttack.fleetDef;let i = index" [class]="'button_fleet_battle bg-signarok-red text-white flex !flex-row items-center gap-2 cursor-pointer !h-8 !p-2'">
            <span class="desc-h text-light-gray"> {{"dashboard.fleet.fleet_def" | translate}} {{fleet.id}}</span>
            <img src="assets/images/icon/view.svg" class="w-4 cursor-pointer" (click)="viewInfoFleet(fleet)" alt="">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="infoPlanetToAttack" class="flex flex-col w-full max-w-7xl items-center justify-center gap-3">
      <div class="flex flex-row w-full items-center justify-center gap-4">
        <button *ngIf="!loadSend" [class]="(fleetAttack && fleetAttack.length === 0 ) || calcStat || this.infoPlanetToAttack.isProtected? 'button !m-0 bg-dark-black text-white opacity-60': 'button bg-purple text-white !m-0 !cursor-pointer'" [disabled]="(fleetAttack && fleetAttack.length === 0) || calcStat || this.infoPlanetToAttack.isProtected" (click)="sendAttack()">{{'dashboard.war.attack'|translate}}</button>
        <button *ngIf="loadSend" class="button bg-purple text-white !m-0 flex items-center justify-center">
          <mat-spinner [diameter]="20"></mat-spinner>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!newBattle" class="flex flex-col gap-10">
    <div class="flex flex-col sm:flex-row w-full items-center justify-between gap-8 md:pr-14">
      <div class="flex flex-row items-center justify-start gap-4">
        <label class="title text-white">{{'dashboard.war.title'|translate}}</label>
        <img src="assets/images/icon/swap_purple.svg" alt="" class="w-5 cursor-pointer" (click)="updateData()"
             [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.update'|translate"/>
      </div>
      <div class="flex flex-col sm:flex-row w-full items-center justify-end gap-8">
        <div class="flex flex-row w-full items-center justify-center sm:justify-end gap-4">
          <span [class]="sortBattle === 'now' ? 'text-sm subtitle text-light-purple !font-bold' :'text-sm subtitle text-light-gray'">{{"dashboard.war.now" | translate}}</span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" aria-labelledby="Toggle" id="Toggle" [disabled]="this.myPlanets === undefined" [value]="sortBattle" [checked]="sortBattle === 'old'" class="sr-only peer outline-0" (click)="setBattle(sortBattle === 'now' ? 'old': 'now', sortBattle === 'now' ? 'off':'all',0,0)">
            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-light-purple peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-light-purple"></div>
          </label>
          <span [class]="sortBattle === 'old' ? 'text-sm subtitle text-light-purple !font-bold' :'text-sm subtitle text-light-gray'">{{"dashboard.war.old" | translate}}</span>
        </div>
        <div class="container_open_new_fleet flex flex-row items-center justify-between cursor-pointer px-4 gap-3" (click)="openNewBattle()">
          <label class="desc-h text-white cursor-pointer">{{"dashboard.war.new_battle" | translate}}</label>
          <img src="assets/images/icon/new_fleet.svg" alt="" class="w-4 cursor-pointer">
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row w-full items-center justify-between gap-2 md:pr-14">
      <div class="flex flex-row w-full max-w-md items-center justify-between gap-2">
        <button *ngIf="sortBattle === 'now'" [class]="type === 'all' ? 'button_war !w-32 bg-light-purple text-white !m-0 flex items-center justify-center':'button_war !w-32 bg-light-gray text-purple !m-0 flex items-center justify-center'" (click)="setBattle(this.sortBattle,'all',0,0)">{{"dashboard.war.all" | translate}}</button>
        <button [class]="type === 'off' ? 'button_war !w-32 bg-light-purple text-white !m-0 flex items-center justify-center':'button_war !w-32 bg-light-gray text-purple !m-0 flex items-center justify-center'" (click)="setBattle(this.sortBattle,'off',0,0)">{{"dashboard.war.attacks" | translate}}</button>
        <button [class]="type === 'def' ? 'button_war !w-32 bg-light-purple text-white !m-0 flex items-center justify-center':'button_war !w-32 bg-light-gray text-purple !m-0 flex items-center justify-center'" (click)="setBattle(this.sortBattle,'def',0,0)">{{"dashboard.war.defenses" | translate}}</button>
      </div>
      <div *ngIf="sortBattle === 'old'" class="flex flex-row w-full max-w-sm items-center justify-end gap-2">
        <label class="desc-h text-white cursor-pointer">{{"dashboard.war.include_revoked" | translate}}</label>
        <input type="checkbox" [checked]="checkedIncludeRevoked" (click)="checkIncludeRevokedBattleOld()"/>
      </div>
    </div>
    <div class="flex flex-col gap-2  md:pr-14">
      <div class="flex flex-col container_tab_attack overflow-x-auto">
        <table *ngIf="filterBattle && filterBattle.length >0" class="table-responsive w-full text-sm text-left bg-transparent shadow-none text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-light-gray uppercase bg-transparent">
          <tr>
            <th scope="col" class="p-2 desc-s text-light-purple cursor-default">{{"dashboard.war.type" | translate}}</th>
            <th scope="col" class="p-2 desc-s text-light-purple cursor-default">{{"dashboard.war.id_planet" | translate}}</th>
            <th scope="col" class="p-2 desc-s text-light-purple cursor-default">{{"dashboard.war.name_planet" | translate}}</th>
            <th *ngIf="this.sortBattle === 'old' " scope="col" class="p-2 desc-s text-light-purple cursor-default">{{"dashboard.war.date" | translate}}</th>
            <th *ngIf="this.sortBattle === 'now'" scope="col" class="p-2 desc-s text-light-purple cursor-pointer" (click)="sortTypeWar(typeSort)">
              <div class="flex flex-row items-center justify-center">
                <label class="cursor-pointer">{{"dashboard.war.time" | translate}}</label>
                <mat-icon class="icon">{{typeSort === 'up'?'arrow_drop_down':'arrow_drop_up'}}</mat-icon>
              </div>
            </th>
            <th *ngIf="this.sortBattle === 'now'" scope="col" class="p-2 desc-s text-light-purple cursor-auto">{{"dashboard.war.consume" | translate}}</th>
            <th *ngIf="this.sortBattle === 'old'" scope="col" class="p-2 desc-s text-light-purple cursor-auto">{{"dashboard.war.fleet_destroyed" | translate}}</th>
            <th *ngIf="this.sortBattle === 'old'" scope="col" class="p-2 desc-s text-light-purple cursor-auto">{{"dashboard.war.enemy_fleet_destroyed" | translate}}</th>
            <th *ngIf="this.sortBattle === 'old' && type !== 'def' " scope="col" class="p-2 desc-s text-light-purple cursor-auto">{{"dashboard.war.reward" | translate}}</th>
            <th *ngIf="this.sortBattle === 'now'" scope="col" class="p-2 desc-s text-light-purple cursor-pointer" (click)="sortProbWar(typeSortProb)">
              <div class="flex flex-row items-center justify-center">
                <label class="cursor-pointer">{{"dashboard.war.probabilities_win" | translate}}</label>
                <mat-icon class="icon">{{typeSortProb === 'up'?'arrow_drop_down':'arrow_drop_up'}}</mat-icon>
              </div>
            </th>
            <th scope="col" class="p-2 desc-s text-light-purple cursor-auto">{{"dashboard.war.outcome" | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let battle of filterBattle;let i = index" [class]="i%2?'bg-transparent text_table text-white justify-between ': 'bg-tab text_table text-white justify-between '">
            <td [attr.aria-label]="'dashboard.war.type' | translate" class="p-2 bg-attack" [ngStyle]="!battle?.confirmed || (planetSelected.id !== battle.planetIdOff && !battle.defWin && battle.winProbability !== null) || (planetSelected.id === battle.planetIdOff && !battle.offWin && battle.winProbability !== null) ? {background:'url(../../../../../assets/images/triangle_red.png)'}:{background:'url(../../../../../assets/images/triangle_purple.png)'}">
              <div class=" flex flex-col items-center justify-center">
                <label *ngIf="planetSelected.id === battle.planetIdOff" class="desc-h text-light-gray !font-bold">ATT</label>
                <label *ngIf="planetSelected.id !== battle.planetIdOff" class="desc-h text-light-gray !font-bold">DEF</label>
              </div>
            </td>
            <td [attr.aria-label]="'dashboard.war.id_planet' | translate" class="p-2 text-light-purple">
              <label *ngIf="planetSelected.id === battle.planetIdOff" class="desc-h text-white">{{battle.planetIdDef}}</label>
              <label *ngIf="planetSelected.id !== battle.planetIdOff" class="desc-h text-white">{{battle.planetIdOff}}</label>
            </td>
            <td [attr.aria-label]="'dashboard.war.name_planet' | translate" class="p-2 text-light-purple">
              <label *ngIf="planetSelected.id === battle.planetIdOff" class="desc-h text-white">{{battle.planetNameDef}}</label>
              <label *ngIf="planetSelected.id !== battle.planetIdOff" class="desc-h text-white">{{battle.planetNameOff}}</label>
            </td>
            <td [attr.aria-label]="'dashboard.war.date' | translate" *ngIf="this.sortBattle === 'old'" class="p-2 w-24 text-light-purple">
              <label [class]=" 'desc-h text-white'">{{battle.timeRevealed *1000 | date: "dd-MM-YYYY HH:mm":"it"}}</label>
            </td>
            <td [attr.aria-label]="'dashboard.war.time' | translate" *ngIf="this.sortBattle === 'now'" class="p-2 w-36 text-light-purple">
              <label *ngIf="this.dateNow > battle.startBattle && !battle.revealed && !battle?.confirmed"
                     [class]="this.dateNow > battle.startBattle + battle.battleDelay.delayAttack ? 'desc-s text-red' : 'desc-s text-light-purple'">{{"dashboard.war.waiting" | translate}}<br>
                <app-timer-countdown [time]="battle.startBattle" class="desc-s text-white mx-auto"></app-timer-countdown>
              </label>
              <label *ngIf="this.dateNow > battle.startBattle && !battle.revealed && battle?.confirmed && !battle.revoked" class="desc-s text-red">{{"dashboard.war.revelation" | translate}}</label>
              <label *ngIf=" this.dateNow < battle.startBattle" class="desc-s text-light-purple">{{"dashboard.war.arriving" | translate}}<br>
                <app-timer [time]="battle.startBattle" class="desc-s text-white mx-auto"></app-timer></label>
              <label *ngIf="planetSelected.id === battle.planetIdOff && battle.returnTime > 0 && this.dateNow < battle.returnTime" class="desc-s text-light-purple">{{"dashboard.war.returning" | translate}}<br>
                <app-timer [time]="battle.returnTime" class="desc-s text-white mx-auto"></app-timer></label>
              <label *ngIf="battle.returnTime > 0 && this.dateNow > battle.returnTime" class="desc-s text-light-purple">{{"dashboard.war.arrived" | translate}}</label>
            </td>
            <td [attr.aria-label]="'dashboard.war.consume' | translate" *ngIf="this.sortBattle === 'now'" class="p-2 w-36 text-light-purple">
              <label *ngIf="this.dateNow > battle.startBattle && !battle.revealed && !battle?.confirmed && this.dateNow >= (battle.startBattle + battle.battleDelay.delayAttack)" class="desc-s text-red">{{(this._planetService.formatBigNumber(battle.battleDelay.amountHydroToConfirm) | currency: '':'':'1.2') + ' HYD'}}</label>
              <label *ngIf="this.dateNow > battle.startBattle && !battle.revealed && !battle?.confirmed && this.dateNow < (battle.startBattle + battle.battleDelay.delayAttack)" class="desc-s text-light-purple">{{('dashboard.war.start_consume_hydro'|translate) + ' ' + getDataTimer(battle.startBattle + battle.battleDelay.delayAttack)}}</label>
            </td>
            <td [attr.aria-label]="'dashboard.war.fleet_destroyed' | translate" *ngIf="this.sortBattle === 'old'" class="p-2 text-light-purple">
              <div *ngIf="planetSelected.id === battle.planetIdOff && !battle.revoked" class="flex flex-row gap-2 items-center justify-end md:justify-start">
                <label class="desc-h text-white">{{battle.amountsBurnOff}}</label>
                <img *ngIf="battle.amountsBurnOff > 0" src="assets/images/icon/view.svg" alt="" class="w-4 cursor-pointer" (click)="viewShips(battle.fleetBurnOff)">
              </div>
              <div *ngIf="planetSelected.id !== battle.planetIdOff && !battle.revoked" class="flex flex-row gap-2 items-center justify-end md:justify-start">
                <label class="desc-h text-white">{{battle.amountsBurnDef}}</label>
                <img *ngIf="battle.amountsBurnDef > 0" src="assets/images/icon/view.svg" alt="" class="w-4 cursor-pointer" (click)="viewShips(battle.fleetBurnDef)">
              </div>
            </td>
            <td [attr.aria-label]="'dashboard.war.enemy_fleet_destroyed' | translate" *ngIf="this.sortBattle === 'old'" class="p-2 text-light-purple">
              <div *ngIf="planetSelected.id === battle.planetIdOff && !battle.revoked" class="flex flex-row gap-2 items-center justify-end md:justify-start">
                <label class="desc-h text-white">{{battle.amountsBurnDef}}</label>
                <img *ngIf="battle.amountsBurnDef > 0" src="assets/images/icon/view.svg" alt="" class="w-4 cursor-pointer" (click)="viewShips(battle.fleetBurnDef)">
              </div>
              <div *ngIf="planetSelected.id !== battle.planetIdOff && !battle.revoked" class="flex flex-row gap-2 items-center justify-end md:justify-start">
                <label class="desc-h text-white">{{battle.amountsBurnOff}}</label>
                <img *ngIf="battle.amountsBurnOff > 0" src="assets/images/icon/view.svg" alt="" class="w-4 cursor-pointer" (click)="viewShips(battle.fleetBurnOff)">
              </div>
            </td>
            <td [attr.aria-label]="'dashboard.war.reward' | translate" *ngIf="this.sortBattle === 'old' && (type === 'off' || type === 'all')" class="p-2 text-light-purple">
              <label *ngIf="planetSelected.id === battle.planetIdOff && !battle.revoked" class="desc-h text-white">{{battle.rewardStolen | currency: '':'':'1.2'}} FDC</label>
            </td>
            <td [attr.aria-label]="'dashboard.war.probabilities_win' | translate" *ngIf="this.sortBattle === 'now'" class="p-2 text-light-purple">
              <label *ngIf="battle.winProbability >= 0 && planetSelected.id === battle.planetIdOff" class="desc-h text-white">{{battle.winProbability}} %</label>
              <label *ngIf="battle.winProbability >= 0 && planetSelected.id !== battle.planetIdOff" class="desc-h text-white">{{100 - battle.winProbability}} %</label>
            </td>
            <td [attr.aria-label]="'dashboard.war.outcome' | translate" *ngIf="this.sortBattle === 'old'" class="p-2 text-light-purple">
              <label *ngIf="planetSelected.id === battle.planetIdOff" [class]="battle.offWin && !battle.revoked ? 'desc-h text-green' : 'desc-h text-red'">{{battle.offWin && !battle.revoked ? ('dashboard.war.win'|translate): battle.revoked ? ('dashboard.war.revoke'|translate) :('dashboard.war.lose'|translate)}}</label>
              <label *ngIf="planetSelected.id !== battle.planetIdOff" [class]="battle.defWin && !battle.revoked ? 'desc-h text-green' : 'desc-h text-red'">{{battle.defWin && !battle.revoked ? ('dashboard.war.win'|translate): battle.revoked ? ('dashboard.war.revoke'|translate) :('dashboard.war.lose'|translate)}}</label>
            </td>
            <td [attr.aria-label]="'dashboard.war.outcome' | translate" *ngIf="this.sortBattle === 'now'" class="p-2 text-light-purple">
              <label *ngIf="planetSelected.id === battle.planetIdOff && battle.revoked" [class]="'desc-h text-red'">{{('dashboard.war.revoke'|translate)}}</label>
              <label *ngIf="planetSelected.id !== battle.planetIdOff && !battle.revoked && battle.revealed" [class]="battle.defWin ? 'desc-h text-green' : 'desc-h text-red'">{{battle.defWin ? ('dashboard.war.win'|translate):('dashboard.war.lose'|translate)}}</label>
              <label *ngIf="planetSelected.id === battle.planetIdOff && !battle.revoked && battle.revealed" [class]="battle.offWin ? 'desc-h text-green' : 'desc-h text-red'">{{battle.offWin ? ('dashboard.war.win'|translate):('dashboard.war.lose'|translate)}}</label>
              <label *ngIf="planetSelected.id !== battle.planetIdOff && battle.revoked" [class]="'desc-h text-red'">{{('dashboard.war.revoke'|translate)}}</label>
            </td>
            <td class="p-2 pr-4">
              <div *ngIf="planetSelected.id === battle.planetIdOff" class="flex flex-row w-full gap-3 sm:gap-2 items-center justify-end ml-auto">
                <div *ngIf="!battle.revoked && !battle?.confirmed && !battle.isDefInPhaseReveal" class="w-5 md:w-4">
                  <img *ngIf="!loadConfirm || battleID !== battle.id" src="assets/images/war_icon/confirm.svg" alt=""  [class]="this.dateNow >= battle.startBattle && (battleID === battle.id || battleID === undefined)? 'w-5 md:w-4 cursor-pointer' : 'w-5 md:w-4 cursor-auto grayscale'"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.confirm'|translate" (click)="confirmAttack(battle,battle.index)">
                  <mat-spinner *ngIf="loadConfirm && battleID === battle.id" [diameter]="20"></mat-spinner>
                </div>
                <div *ngIf="!battle.revoked && !battle?.confirmed && battle.isDefInPhaseReveal && this.planetSelected.id !== battle.infoReveal.planetIdOff" class="w-5 md:w-4">
                  <img *ngIf="!loadForceReveal || battleID !== battle.id" src="assets/images/war_icon/force_reveal.svg" alt=""  [class]="(battleID === battle.id || battleID === undefined)? 'w-5 md:w-4 cursor-pointer' : 'w-5 md:w-4 cursor-auto grayscale'"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.force_reveal'|translate" (click)="forceRevealAttack(battle)">
                  <mat-spinner *ngIf="loadForceReveal && battleID === battle.id " [diameter]="20"></mat-spinner>
                </div>
                <div *ngIf="!battle.revoked && !battle?.confirmed" class="w-5 md:w-4">
                  <img *ngIf="!loadRevoke || battleID !== battle.id" src="assets/images/war_icon/revoke.svg" alt=""  [class]="(battleID === battle.id || battleID === undefined)? 'w-5 md:w-4 cursor-pointer' : 'w-5 md:w-4 cursor-auto grayscale'"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.revoked'|translate" (click)="revokeAttack(battle,battle.index)">
                  <mat-spinner *ngIf="loadRevoke && battleID === battle.id" [diameter]="20"></mat-spinner>
                </div>
                <div *ngIf="!battle.revoked && !battle?.confirmed && battle.battleDelay?.timeToPay > 0" class="w-5 md:w-4">
                  <img *ngIf="!loadEmergencyRevoke || battleID !== battle.id" src="assets/images/war_icon/emergency_revoke.svg" alt=""  [class]="(battleID === battle.id || battleID === undefined)? 'w-5 md:w-4 cursor-pointer' : 'w-5 md:w-4 cursor-auto grayscale'"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.emergency_revoke'|translate" (click)="emergencyRevokeAttack(battle,battle.index)">
                  <mat-spinner *ngIf="loadEmergencyRevoke && battleID === battle.id" [diameter]="20"></mat-spinner>
                </div>
                <div *ngIf="battle?.confirmed && !battle.revealed " class="w-5 md:w-4">
                  <img *ngIf="!loadReveal || battleID !== battle.id" src="assets/images/war_icon/reveal.svg" alt=""  [class]="(battleID === battle.id || battleID === undefined)? 'w-5 md:w-4 cursor-pointer' : 'w-5 md:w-4 cursor-auto grayscale'"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.reveal'|translate" (click)="revealAttack(battle,battle.index)">
                  <mat-spinner *ngIf="loadReveal && battleID === battle.id" [diameter]="20"></mat-spinner>
                </div>
                <div class="w-5 md:w-4">
                  <img *ngIf="!battle.revoked" [src]="(!battle?.confirmed || !battle.revealed) && !battle.revoked ?'assets/images/war_icon/view.svg':'assets/images/icon/info.svg'" alt=""  [class]="'w-5 md:w-4 cursor-pointer'" (click)="goToPage('/dashboard/war/off/'+this.sortBattle+'/'+battle.id)"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="(!battle?.confirmed || !battle.revealed) && !battle.revoked ?('dashboard.war.preview_battle'|translate):('dashboard.war.info_battle'|translate)">
                </div>
              </div>
              <div *ngIf="planetSelected.id !== battle.planetIdOff" class="flex flex-row w-full gap-3 sm:gap-2 items-center justify-end ml-auto">
                <div *ngIf="!battle.revoked && !battle?.confirmed && battle.canDefRevoke" class="w-5 md:w-4">
                  <img *ngIf="!loadEmergencyRevokeDef || battleID !== battle.id" src="assets/images/war_icon/emergency_revoke.svg" alt=""  [class]="(battleID === battle.id || battleID === undefined)? 'w-5 md:w-4 cursor-pointer' : 'w-5 md:w-4 cursor-auto grayscale'"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.scare_off_fleets'|translate" (click)="emergencyRevokeAttackDef(battle,battle.index)">
                  <mat-spinner *ngIf="loadEmergencyRevokeDef && battleID === battle.id" [diameter]="20"></mat-spinner>
                </div>
                <div *ngIf="battle?.confirmed && !battle.revealed " class="w-5 md:w-4">
                  <img *ngIf="!loadReveal || battleID !== battle.id" src="assets/images/war_icon/reveal.svg" alt=""  [class]="(battleID === battle.id || battleID === undefined)? 'w-5 md:w-4 cursor-pointer' : 'w-5 md:w-4 cursor-auto grayscale'"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.reveal'|translate" (click)="revealAttackDef(battle,battle.index)">
                  <mat-spinner *ngIf="loadReveal && battleID === battle.id" [diameter]="20"></mat-spinner>
                </div>
                <div class="w-5 md:w-4">
                  <img *ngIf="!battle.revoked"  [src]="(!battle?.confirmed || !battle.revealed) && !battle.revoked ?'assets/images/war_icon/view.svg':'assets/images/icon/info.svg'" alt=""  [class]="'w-5 md:w-4 cursor-pointer'" (click)="goToPage('/dashboard/war/def/'+this.sortBattle+'/'+battle.id)"
                       [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="(!battle?.confirmed || !battle.revealed) && !battle.revoked ?('dashboard.war.preview_battle'|translate):('dashboard.war.info_battle'|translate)">
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <nav *ngIf="sortBattle === 'old' && this.maxBattlePage" class="flex items-center flex-column flex-wrap md:flex-row justify-end pt-4" aria-label="Table navigation">
        <!--<span class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing <span class="font-semibold text-gray-900 dark:text-white">{{(page *5) +1}}-{{(page *5) +5 > maxBattle ? maxBattle : (page *5) +5}}</span> of <span class="font-semibold text-gray-900 dark:text-white">{{maxBattle}}</span></span>-->
        <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 gap-2">
          <li>
            <img src="assets/images/icon/arrow_back.svg" [class]="page > 0 ? 'cursor-pointer': '!grayscale'"  (click)="page > 0 && setPage(page - 1)"  alt="">
          </li>
          <li >
            <label class="subtitle text-white" >{{this.page +1}}</label>
          </li>
          <li>
            <img src="assets/images/icon/arrow_next.svg" [class]="page + 1 <= this.maxBattlePage.length -1 ? 'cursor-pointer': '!grayscale'" (click)="page + 1 <= this.maxBattlePage.length -1 && setPage(page + 1)" alt="">
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
<div *ngIf="this.loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
  <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
</div>
